import register from 'preact-custom-element'
import styles from './newsletter-widget.scss'
import {useEffect, useState} from "preact/hooks";
import {getTranslationService, Translator} from "../../services/translation/translation-service";
import {getCountryService} from "../../services/country/country-service";
import {createRef} from "preact";
import {NewsletterModal} from "./newslettter-modal";
import {EventAction, EventDetails, isBirthdayCouponCountry, NewsletterTrackingEvent, WidgetContext} from './model';

function NewsletterWidget() {
  const [i18n, setI18n] = useState<Translator>(null);
  const [countryCode] = useState<string>(getCountryService().getCountryCodeFromLocation());
  const [email, setEmail] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const signUpButton = createRef();

  useEffect(() => {
    const ts = getTranslationService()
    ts.initTranslations('208671', countryCode)
      .then(translations => setI18n(translations))
  }, [countryCode])

  function handleEmailChanged(e: Event) {
    const value = (e.target as HTMLInputElement).value
    setEmail(value);
    const button = signUpButton.current as HTMLButtonElement;
    button.disabled = !validateEmail(value);
  }

  function validateEmail(email: string) {
    return !!email.match(/\S+@\S+\.\S{2,}/g);
  }

  function dispatchEvent(event: EventDetails) {
    document.dispatchEvent(new CustomEvent(NewsletterTrackingEvent, {detail: event}));
  }

  function dispatchGlobalEvent(event: string) {
    window.dispatchEvent(new Event(event));
  }

  function onClose() {
    setActive(false);
    dispatchEvent({action: EventAction.CLOSE});
  }

  function onOpen() {
    setActive(true);
    dispatchEvent({action: EventAction.OPEN});
  }

  if (!i18n) {
    return;
  }

  return (
    <>
      <style>{styles}</style>
      <WidgetContext.Provider value={{
        countryCode: countryCode,
        i18n: i18n,
        email: email,
        actions: {validateEmail: validateEmail, trackEvent: dispatchEvent, dispatchGlobalEvent: dispatchGlobalEvent}
      }}>
        <div class="inline-newsletter">
          <div class="newsletter-container">
            <div class="text-container">
              <div class="newsletter-header">{i18n.translate("newsletter.inline.headline")}</div>
              <div class="description">{i18n.translate("newsletter.inline.description")}</div>
            </div>
            <div className="controls">
              <div class="email-input-container">
                <input type="email" class="text-input" onInput={handleEmailChanged}
                       placeholder={i18n.translate('newsletter.inline.input.email.placeholder')}
                       onfocusout={() => setIsEmailValid(email === '' ? false : !validateEmail(email))}
                />

                {
                  isEmailValid && (
                    <div className="error" id="login-email-error">
                      <svg className="error-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248
                            248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418
                            136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982
                            11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                        />
                      </svg>
                      <span className="error-info">{i18n.translate('newsletter.warning.valid.email')}</span>
                    </div>
                  )
                }
              </div>

              <button onClick={onOpen} ref={signUpButton} class="button"
                      disabled>{i18n.translate("newsletter.inline.button.subscribe")}</button>
            </div>
            {isBirthdayCouponCountry(countryCode) &&
              <div class="hint">{i18n.translate("newsletter.inline.footnote")}</div>}
          </div>
          {active && <NewsletterModal closeCallback={onClose}/>}
        </div>
      </WidgetContext.Provider>
    </>
  )
}

register(NewsletterWidget, 'newsletter-widget', [], {shadow: true})
