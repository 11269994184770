import {html, LitElement, TemplateResult} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import {getTranslationService, Translator} from "../../services/translation/translation-service";
import styles from './competition-widget.scss';
import {Competition, FieldItem, FieldType, Participation, ParticipationError} from "../../services/competition/models";
import {getCompetitionService} from "../../services/competition/competition-service";
import {Customer} from "../../services/customer/customer";
import {getCustomerService} from "../../services/customer/customer-service";
import {getCountryService} from "../../services/country/country-service";

@customElement('competition-widget')
export class CompetitionWidget extends LitElement {

  @property() competitionId: string;
  @property() termsUrl: string;
  @state() translator: Translator;
  @state() competition: Competition;
  @state() participation: Participation = new Participation({});
  @state() customer: Customer;
  @state() errorMessage: string;
  @state() successMessage: string;
  @state() isEmailValid: boolean;

  async connectedCallback() {
    super.connectedCallback();
    this.renderCustomField = this.renderCustomField.bind(this);
    console.debug(this.competitionId);
    this.translator = await getTranslationService().initTranslations('235977', getCountryService().getCountryCodeFromUrl());
    this.competition = await getCompetitionService().loadCompetition(this.competitionId);
    console.debug(this.competition);
    getCustomerService().subscribeForCustomer('COMPETITION-WIDGET', this.onCustomerSubscription.bind(this));
    this.participation = this.competition.createParticipation();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  onCustomerSubscription(customer: Customer) {
    this.customer = customer;

    const customerData = {
      email: customer?.email,
      firstName: customer?.profile?.firstName,
      lastName: customer?.profile?.lastName,
      gender: customer?.profile?.gender,
      country: customer?.countryCode,
      dressSize: customer?.profile?.size
    }
    this.participation = this.competition.createParticipation(customerData);
  }

  handleParticipationChange(e: Event) {
    const {id, type, checked, value} = e.target as HTMLInputElement;
    this.participation = new Participation({...this.participation, [id]: type === 'checkbox' ? checked : value});
  }

  handleParticipationCustomFieldChange(e: Event) {
    const {id, value, type} = e.target as HTMLInputElement;
    const fieldIndex = this.participation.fields.findIndex(it => it.name === id);

    // date elements don't have placeholders -> style them based on data-attribute
    if (type === FieldType.Date) {
      const node = e.target as Element;
      node.toggleAttribute("data-placeholder", !value)
    }

    // updating nested values in fun :)
    this.participation.fields[fieldIndex].value = value;
    this.participation = new Participation({...this.participation, fields: [...this.participation.fields]});
  }

  async onSubmit(e: Event) {
    e.preventDefault();
    this.successMessage = null; //clear
    this.errorMessage = null; //clear
    if (!this.participation.isValid()) {
      return;
    }

    try {
      await getCompetitionService().participate(this.competitionId, this.participation);
      this.successMessage = this.translator.translate('widget.participate.success');
      this.participation = this.competition.createParticipation(); // reset
    } catch (e) {
      const err = e as ParticipationError;
      if (err.code === 100) {
        // validation failed
        this.errorMessage = this.translator.translate('widget.error.validation');
      } else if (err.code === 200) {
        // already participated
        this.errorMessage = this.translator.translate('widget.error.alreadyParticipated');
      } else {
        // other error
        console.log('error while participating: ', err);
        this.errorMessage = this.translator.translate('widget.error.other');
      }
    }
  }

  replaceWildcard(key: string, replacement: TemplateResult) {
    const translation = this.translator.translate(key)?.split('{0}');
    return translation ? html`${translation[0]}${replacement}${translation[1]}` : key;
  }

  performEmailValidation(){
    this.isEmailValid = !this.getEmailValidationLogic(this.participation.email);
  }

  getEmailValidationLogic = (email) => {
    if(!email) return false;
    const regex = new RegExp('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$');
    return regex.test(email.toString());
  }

  render() {
    if (!this.translator || !this.competition || !this.participation) {
      return null;
    }

    if (!this.competition.isValid()) {
      return html`
        <style>${styles}</style>
        <div class="competition-widget">
          <div class="title">${this.competition.name}</div>
          <div class="competition-invalid">
            ${this.translator.translate('widget.competition.notValid')}
          </div>
        </div>
      `
    }

    return html`
    <style>${styles}</style>
    <div class="competition-widget">
      <form @submit="${this.onSubmit}">
      <div class="email-container">
        <label>${this.translator.translate('widget.email.label')}*</label>
        <input id="email" type="email" placeholder="${this.translator.translate('widget.email.placeholder')}*" @focusout="${this.performEmailValidation}" .value="${this.participation.email}" @keyup="${this.handleParticipationChange}">
        ${this.participation.email !== "" ?
          (this.isEmailValid ? html`
            <div class="error">
              <svg class="error-icon">
                <use xlink:href="/img/icons/sprites.svg#exclamation-circle"/>
              </svg>
              <span class="error-info">${this.translator.translate('widget.error.validation')}</span>
            </div>
          `: html``) : html``}
      </div>

        ${this.participation.fields.map(this.renderCustomField)}

        <div class="checkbox-container">
         <input id="termsParticipation" type="checkbox" @click="${this.handleParticipationChange}" .checked="${this.participation.termsParticipation}" required>
          <label for="termsParticipation">
            <span class="checkbox"><svg><use xlink:href="/img/icons/sprites.svg#check"></use></svg></span>
            <span class="text">${this.replaceWildcard('widget.terms.participation', html`<a href="${this.termsUrl ? this.termsUrl : this.competition.terms ? this.competition.terms : '/legal/terms-competition/'}" target="_blank">${this.translator.translate('widget.terms.participation.linkText')}</a>`)}</span>
          </label>
        </div>

        <div class="checkbox-container">
         <input id="termsAdvertisement" type="checkbox" @click="${this.handleParticipationChange}" .checked="${this.participation.termsAdvertisement}">
          <label for="termsAdvertisement">
            <span class="checkbox"><svg><use xlink:href="/img/icons/sprites.svg#check"></use></svg></span>
            <span class="text">${this.replaceWildcard('widget.terms.advertisement', html`<a href="/legal/privacy/#newsletter" target="_blank">${this.translator.translate('widget.terms.advertisement.linkText')}</a>`)}</span>
          </label>
        </div>

        <div class="privacy-container">
          ${this.replaceWildcard('widget.terms.privacy', html`<a href="/legal/privacy/" target="_blank">${this.translator.translate('widget.terms.privacy.linkText')}</a>`)}
        </div>

        <button type="submit" .disabled="${!this.participation.isValid()}">${this.translator.translate('widget.submit.button')}</button>

        <div class="messages">
            ${this.errorMessage && html`<div class="error-message"><svg class="icon"><use xlink:href="/img/icons/sprites.svg#exclamation-circle"/></svg><span class="message">${this.errorMessage}</span></div>`}
            ${this.successMessage && html`<div class="success-message">${this.translator.translate('widget.participate.success')}</div>`}
        </div>

        <div class="required-hint">${this.translator.translate('widget.terms.requiredHint')}</div>
      </form>
    </div>
    `
  }

  renderCustomField(field: FieldItem) {
    if (field.type === FieldType.TextArea) {
      return html`<textarea id="${field.name}" placeholder="${field.placeholder}" .value="${field.value}" ?required="${field.required}" @keyup="${this.handleParticipationCustomFieldChange}" @change="${this.handleParticipationCustomFieldChange}"> `
    }

    if (field.type === FieldType.Date) {
      return html`
        <div class="email-container">
          <label>${field.placeholder}</label>
          <input id="${field.name}" type="date" data-placeholder .value="${field.value}" ?required="${field.required}" @keyup="${this.handleParticipationCustomFieldChange}" @change="${this.handleParticipationCustomFieldChange}">
        </div>
      `
    }

    return html`<input id="${field.name}" type="${field.type.toLowerCase()}" placeholder="${field.placeholder}" .value="${field.value}" ?required="${field.required}" @keyup="${this.handleParticipationCustomFieldChange}" @change="${this.handleParticipationCustomFieldChange}">`
  }
}
