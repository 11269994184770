import {Registration} from "./registration";
import {Completion} from "./completion";
import {useContext, useState} from "preact/hooks";
import {EventAction, NewsletterSuccessEvent, WidgetContext} from "./model";

interface Props {
  closeCallback(): void;
}

export function NewsletterModal(props: Props) {
  const [complete, setComplete] = useState<boolean>(false);
  const {actions} = useContext(WidgetContext);
  const {trackEvent, dispatchGlobalEvent} = actions;

  function onComplete() {
    setComplete(true);
    trackEvent({action: EventAction.SUCCESS});
    dispatchGlobalEvent(NewsletterSuccessEvent);
  }

  function onFailed() {
    trackEvent({action: EventAction.FAILED});
  }

  return (
    <div class="modal">
      <div class="modal-content">
        <button class="modal-close-button" onClick={props.closeCallback}>
            <span aria-hidden="true" class="icon solid">
              <svg><use href="/img/icons/sprites.svg#times-circle" /></svg>
            </span>
        </button>

        <div class="content-center">
          {!complete && <Registration completionCallback={onComplete} failedCallback={onFailed}/>}
          {complete && <Completion/>}
        </div>
      </div>
    </div>
  )
}
