import {useContext, useEffect, useRef, useState} from "preact/hooks";
import {isBirthdayCouponCountry, WidgetContext} from "./model";
import {getCustomerService} from "../../services/customer/customer-service";
import config from "../../config";

interface Props {
  completionCallback(): void;
  failedCallback(): void;
}

interface State {
  email: string;
  birthday: string;
  interestFemale: boolean;
  interestMale: boolean;
  acceptedPrivacy: boolean;
  acceptedTerms: boolean;
}

export function Registration(props: Props) {
  const {i18n, countryCode, email, actions} = useContext(WidgetContext);
  const [state, setState] = useState<State>(null);
  const emailInput = useRef<HTMLInputElement>();
  const dateInput = useRef<HTMLInputElement>();
  const interestFemale = useRef<HTMLInputElement>();
  const interestMale = useRef<HTMLInputElement>();
  const privacy = useRef<HTMLInputElement>();
  const terms = useRef<HTMLInputElement>();
  const signUpButton = useRef<HTMLButtonElement>();
  const currentDate = new Date().toISOString().split("T")[0];
  const minDate = new Date('1900-01-01').toISOString().split("T")[0];
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

  useEffect(() => {
    (emailInput.current as HTMLInputElement).value = email;
  }, [])

  function toggleCheckbox(box: HTMLInputElement, e?: Event) {
    if (e && e.target instanceof HTMLAnchorElement) {
      e.stopPropagation();
      return;
    }

    box.checked = !box.checked;
    validateRegistration()
  }

  function validateRegistration() {
    const button = signUpButton.current as HTMLButtonElement;
    const {validateEmail} = actions;

    const email = (emailInput.current as HTMLInputElement).value;
    const birthday = (dateInput.current as HTMLInputElement) ? (dateInput.current as HTMLInputElement).value : null;
    const checkFemale = (interestFemale.current as HTMLInputElement).checked;
    const checkMale = (interestMale.current as HTMLInputElement).checked;
    const acceptPrivacy = (privacy.current as HTMLInputElement) ? (privacy.current as HTMLInputElement).checked : true;
    const acceptTerms = (terms.current as HTMLInputElement).checked;

    setState({email: email, birthday: birthday, interestFemale: checkFemale, interestMale: checkMale, acceptedPrivacy: acceptPrivacy, acceptedTerms: acceptTerms});

    const valid = validateEmail(email) && acceptPrivacy && acceptTerms;
    button.disabled = !valid;
  }

  async function submitRegistration() {
    const button = signUpButton.current as HTMLButtonElement;
    button.disabled = true;
    button.classList.add('is-loading');

    const req = {
      email: state.email,
      countryCode: countryCode,
      birthday: state.birthday ? new Date(state.birthday) : null,
      optInEmailAdvertisementConsent: state.acceptedTerms,
      optInEmailChannelLoyalty: countryCode === 'de',
      optInEmailChannelNewsletter: state.acceptedTerms,
      optInEmailChannelWomensFashion: state.interestFemale,
      optInEmailChannelMensFashion: state.interestMale
    };

    try {
      await getCustomerService().subscribeForNewsletter(req);
      props.completionCallback();
    } catch (e) {
      console.error('[newsletter-registration]: error while registering for newsletter:', e);
      props.failedCallback();
    } finally {
      button.disabled = false;
      button.classList.remove('is-loading');
    }
  }

  return (
    <div class="step-1">
      <h1 class="font-bigger-header">{i18n.translate("newsletter.inline.modal.headline")}</h1>
      <div class="modal-inputs">
        <div className="email-input-container">
          <input ref={emailInput}
                 aria-label={i18n.translate("newsletter.inline.input.email.placeholder")}
                 type="email"
                 onfocusout={() => setIsEmailValid(state.email === "" ? false : !actions.validateEmail(state.email))}
                 class="text-input input-email"
                 onInput={validateRegistration}
                 placeholder={`${i18n.translate("newsletter.inline.input.email.placeholder")}*`}
          />

          {
            isEmailValid && (
              <div className="error" id="login-email-error">
                <svg className="error-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248
                              248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418
                              136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982
                              11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                  />
                </svg>
                <span className="error-info">{i18n.translate('newsletter.warning.valid.email')}</span>
              </div>
            )
          }
        </div>

        {isBirthdayCouponCountry(countryCode) &&
          <input ref={dateInput} type="date" min={minDate} max={currentDate} class="text-input input-birthday"
                 onInput={validateRegistration} placeholder={i18n.translate("newsletter.inline.input.birthday.placeholder")} />
        }
      </div>
      <div class="intrests-container">
        <p class="font-radio-label">{i18n.translate("newsletter.inline.interests.for")}</p>

        <div class="intrest-radio-wrapper">
          <div class="radio-container" onClick={() => toggleCheckbox(interestFemale.current)}>
            <input ref={interestFemale} class="radiobutton" type="checkbox" name="radio-intrest-select" />
              <label />
              <span class="radio-label">{i18n.translate("newsletter.inline.interests.female")}</span>
          </div>

          <div class="radio-container" onClick={() => toggleCheckbox(interestMale.current)}>
            <input ref={interestMale} class="radiobutton" type="checkbox" name="radio-intrest-select" />
            <label />
            <span class="radio-label">{i18n.translate("newsletter.inline.interests.male")}</span>
          </div>
        </div>
      </div>

      <div class="modal-legals-container">

        {countryCode !== 'de' &&
        <div class="checkbox-container" onClick={(e) => toggleCheckbox(privacy.current, e)}>
          <input ref={privacy} class="checkbox" type="checkbox" name="radio-intrest-select" />
          <label/>
          <span class="checkbox-label" dangerouslySetInnerHTML={{__html: `${i18n.translate('newsletter.inline.legal.privacy', `${countryCode !== config.defaultLang ? `/${countryCode}` : ''}/legal/privacy/`)}*`}} />
        </div>
        }

        <div class="checkbox-container space-top" onClick={(e) => toggleCheckbox(terms.current, e)}>
          <input ref={terms} class="checkbox" type="checkbox" name="radio-intrest-select" />
            <label/>
            <span class="checkbox-label" dangerouslySetInnerHTML={{__html: `${i18n.translate('newsletter.inline.use-of-data', `${countryCode !== config.defaultLang ? `/${countryCode}` : ''}/legal/privacy/#newsletter`)}*`}} />
        </div>

        {countryCode === 'de' &&
        <div class="font-legals-terms space-top space-left">
          <span class="checkbox-label" dangerouslySetInnerHTML={{__html: i18n.translate('newsletter.inline.legal.privacy', `${countryCode !== config.defaultLang ? `/${countryCode}` : ''}/legal/privacy/`)}} />
        </div>
        }
        <div class="required-text">{i18n.translate("newsletter.inline.required.text")}</div>
      </div>

      <div class="sign-up-button-container">
        <button ref={signUpButton} class="button modal-button" onClick={submitRegistration} disabled>
          <svg><use href="/img/icons/sprites.svg#loading-indicator"/></svg>
          <span>{i18n.translate("newsletter.inline.button.subscribe")}</span>
        </button>
      </div>

    </div>
  )
}
